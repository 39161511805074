import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
	EffectFade,
	Navigation,
	Controller,
	Mousewheel,
	Autoplay,
} from 'swiper'
import { getColor, breakpoint } from '../../shared/theme'

import swiperStyles from 'swiper/swiper.scss'
import swiperFade from 'swiper/components/effect-fade/effect-fade.scss'
console.log({ swiperStyles, swiperFade })

SwiperCore.use([Navigation, EffectFade, Controller, Mousewheel, Autoplay])

const SliderHeader = styled.section`
	width: 100%;
	height: 100vh;
	position: relative;
	background: ${(props) =>
		props.background ? props.background : getColor('brown')};
	transition: all 600ms ${(props) => props.theme.ease};
	padding: 160px 0 80px;

	@media ${breakpoint('tablet')} {
		padding: 80px 0;
	}
`

const Darr = styled.button`
	display: none;
	content: '';
	width: 30px;
	height: 30px;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, calc(-40px - 50%));

	@media ${breakpoint('tablet')} {
		display: block;
		transform: translate(-50%, calc(((100vh - ((600 / 1020) * (46.59vw))) / 4 * -1) + 50%) );
	}

	svg {
		fill: none;
		stroke: ${getColor('white')};
		stroke-miterlimit: 10;
	}
`

const HeaderWrapper = styled(Swiper)`
	height: 100%;
	display: flex;
	align-items: center;
`

const HeaderImgWrapper = styled.div`
	width: 100%;
	display: block;
	overflow: hidden;
	position: relative;
	cursor: grab;

	&::before {
		content: '';
		display: block;
		position: relative;
		width: 100%;
		padding-top: ${() => {
			return `${(450 / 295) * 100}%`
		}};

		@media ${breakpoint('mobile')} {
			padding-top: 100%;
		}

		@media ${breakpoint('laptop')} {
			padding-top: ${() => {
				return `${(600 / 1020) * 100}%`
			}};
		}
	}
`

const ProjectsSlide = styled.div`
	flex: 1;
	position: relative;
	display: block;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 0;
		position: relative;
		padding-top: 100%;
	}
`

const ProjectsImg = styled.img``

const WorkSliderWrapper = styled.section`
	.swiper-button-next {
		order: 999;
		display: none;
		justify-content: flex-end;

		&::after {
			content: 'Next';
			display: block;
			width: ${() => {
				return `${(362 / 915) * 100}%`
			}};
		}
	}

	.swiper-button-prev
	{
		order: 998;
		display: none;

		&::after {
			content: 'Prev';
			text-align: right;
			display: block;
			width: ${() => {
				return `${(362 / 915) * 100}%`
			}};
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		order: 998;
		outline: none;
		font-family: ${(props) => props.theme.fonts.goodSans};
		font-size: 22px;
		letter-spacing: -1px;
		text-decoration: underline;
		font-weight: 700;
		color: ${getColor('black')};
		padding-left: 20px;
		display: none;
		background: ${getColor('greyLight')};
		cursor: pointer;
		width: 50%;

		@media ${breakpoint('tablet')} {
			padding: 48px 32px;
			display: flex;
			font-size: 24px;
		}

		@media ${breakpoint('desktop')} {
			padding: 64px 44px;
			font-size: 28px;
		}
	}
`

const SwiperWrapper = styled(Swiper)`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`

const ImgWrapper = styled.div`
	position: relative;
	display: flex;
	pointer-events: none;
	flex-direction: column;

	@media ${breakpoint('tablet')} {
		flex-direction: row;
	}
`

const Title = styled.h1`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: ${getColor('white')};
	font-weight: 700;
	line-height: 1.03;
	text-align: center;
	padding: 30px 0;
	width: calc(100% - 60px);
	font-size: 38px;

	@media ${breakpoint('tablet')} {
		font-size: 44px;
	}

	@media ${breakpoint('desktop')} {
		font-size: 68px;
	}
`

const Img = styled.img`
	object-fit: cover;
	height: 100%;
	width: 100%;
`

const WorkSlider = ({ projects }) => {
	const [firstSwiper, setFirstSwiper] = useState(null)
	const [secondSwiper, setSecondSwiper] = useState(null)
	const [backgroundColor, setBackground] = useState(null)
	const swiperHeader = useRef(null)

	function changeBg(activeIndex) {
		const sliderHeader = swiperHeader.current
		const slides = sliderHeader.querySelectorAll('.swiper-slide')
		let activeColor = slides[activeIndex].getAttribute('data-color')

		setBackground(activeColor)
	}

	if (!projects) {
		return null
	}

	const { workClients } = projects

	return (
		<>
			<SliderHeader background={backgroundColor}>
				<HeaderWrapper
					ref={swiperHeader}
					centeredSlides
					allowTouchMove={true}
					freeMode={false}
					mousewheel={{
						forceToAxis: true,
					}}
					// speed={1200}
					// autoplay={{
					// 	delay: 3200,
					// }}
					loop={true}
					onSwiper={({ activeIndex }) => changeBg(activeIndex)}
					onSlideChange={({ activeIndex }) => changeBg(activeIndex)}
					onSwiper={setFirstSwiper}
					controller={{ control: secondSwiper }}
					breakpoints={{
						0: {
							spaceBetween: 16,
							slidesPerView: 1.175,
						},
						640: {
							spaceBetween: 48,
							slidesPerView: 1.175,
						},
						1199: {
							spaceBetween: 130,
							slidesPerView: 2,
						},
					}}
				>
					{workClients.map(function (client, index) {
						return (
							<SwiperSlide
								key={index}
								className="worksection-swiper-slide"
								data-color={client.color}
								key={index}
							>
								<HeaderImgWrapper>
									<Img
										src={client.heroImage.mediaItemUrl}
										className="bg-cover"
									/>
									<Title
										dangerouslySetInnerHTML={{ __html: client.name }}
									></Title>
								</HeaderImgWrapper>
							</SwiperSlide>
						)
					})}
				</HeaderWrapper>

				<Darr>
					<svg viewBox="0 0 16.4 15.7">
						<path class="st0" d="M0.3,7.6L8.2,15L16,7.6 M8.2,15V0"/>
					</svg>
				</Darr>

			</SliderHeader>

			<WorkSliderWrapper>
				<SwiperWrapper
					onSwiper={setSecondSwiper}
					controller={{ control: firstSwiper }}
					watchSlidesVisibility
					watchSlidesProgress
					slidesPerView={2}
					effect="fade"
					spaceBetween={0}
					loop={true}
					navigation
				>
					{workClients.map(function (client, index) {
						return (
							<SwiperSlide key={index}>
								<ImgWrapper>
									{client.gallery.map(function (el, index) {
										return (
											<ProjectsSlide key={index}>
												<ProjectsImg
													src={el.mediaItemUrl}
													className="bg-cover"
												/>
											</ProjectsSlide>
										)
									})}
								</ImgWrapper>
							</SwiperSlide>
						)
					})}
				</SwiperWrapper>
			</WorkSliderWrapper>
		</>
	)
}

export default WorkSlider
